import { BaseContent } from '..';
import useTranslation from 'next-translate/useTranslation';
import { NotificationContentProps } from '../props';
import { TransactionExtraParams } from '@/models/notification/notificationExtraParams';
import NotificationCoin from '../../Coin';
import { WalletService } from '@starsoft/common/services';
import { CoinType, DepositNotification } from '@starsoft/common/models';
import { TransactionStatus } from '@starsoft/common/enums';

export default function DepositContent({
  notification,
  type,
}: NotificationContentProps<DepositNotification>) {
  const { t } = useTranslation('common');
  const extra: TransactionExtraParams = notification.extra;

  function getDescription(): string {
    switch (extra?.status) {
      case TransactionStatus.Pending:
        return 'notification_deposit_pending_description';
      default:
        return 'notification_deposit_description';
    }
  }

  function getTitle(): string {
    switch (extra?.status) {
      case TransactionStatus.Pending:
        return 'notification_deposit_pending_title';
      default:
        return 'notification_deposit_title';
    }
  }

  return (
    <BaseContent
      icon="fa-solid fa-piggy-bank"
      title={t(getTitle())}
      description={t(getDescription(), {
        amount: WalletService.maskCurrency({
          amount: extra.amount,
          coin: extra.coin,
          hideSymbol: extra.coin.type === CoinType.Fiat,
        }),
      })}
      components={{
        coin: <NotificationCoin coin={extra.coin} />,
      }}
      type={type}
      createdAt={notification?.createdAt ?? ''}
      notificationTypeId={notification?.type?.id}
      extra={notification?.extra}
    />
  );
}
